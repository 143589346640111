import { useEffect } from "react";
function Home()
{
    useEffect(()=>{
        window.location.href='https://classplusapp.com/';

    },[])
    return (
        <div>
            
        </div>
    )
}
export default Home;